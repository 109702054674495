import React, { Component } from "react";
import "./LandingPage.css";
import FadeIn from "react-fade-in";
import ReactGA from "react-ga";

ReactGA.initialize("UA-164355955-1");
ReactGA.pageview(window.location.pathname +"LandingPage");

class LandingPage extends Component {

  constructor(props) {
    super(props);

    ReactGA.initialize("UA-164355955-1");
    ReactGA.pageview(window.location.pathname +"LandingPage");

  }

  render() {
    return (
      <div>
        <FadeIn delay="1000" transitionDuration="1500">
          <h1 class="top"> Now boarding the struggle bus.</h1>
        </FadeIn>
        <FadeIn delay="2500" transitionDuration="1500">
          <h1 class="bottom" name="App" onClick={this.props.clickBtn}>
            Click to ride. >>
          </h1>
        </FadeIn>
      </div>
    );
  }
}

export default LandingPage;
