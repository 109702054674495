import React, { Component } from "react";
import "./App.css";
import { Grid, Row, Col } from "react-flexbox-grid";
import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga";



/*
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
*/
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

//import current_bus from "./images/Bus0LOOP.gif"
var images = require.context("../public/images", true);

// This hard coded index is gonna be gre
class BusImage extends Component {
  constructor(props) {
    super(props);

    let preloadedImages = [];
    for (var i = 0; i < props.array.length; i++) {
      const img = new Image();
      img.src = images(props.array[i]);
      preloadedImages.push(img);
    }

    this.state = {
      currentIndex: props.currentIndex,
      array: props.array,
      preloaded_array: preloadedImages,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.currentIndex !== nextProps.currentIndex) {
      this.setState({ currentIndex: nextProps.currentIndex });
      return true;
    } else {
      return false;
    }
  }

  render() {
    const preloaded_image = this.state.preloaded_array[this.state.currentIndex];
    return (
      <div>
        <Default>
          <img src={preloaded_image.src} className="busGif" alt="" />
        </Default>
        <Mobile>
          {this.state.preloaded_image}
          <img src={preloaded_image.src} className="mobile_busGif" alt="" />
        </Mobile>
      </div>
    );
  }
}

class StruggleForm extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "", isAnimating: props.isAnimating, isFinal: props.isFinal };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.submit_action(this.state.value);
    this.setState({ value: "" });
  }

  
  componentWillReceiveProps(nextProps) {
    this.setState({ isAnimating: nextProps.isAnimating, isFinal: nextProps.isFinal });
  }
  

  // Form button will be disabled when image is animating.
  // Entire form disabled during final animation
  render() {

    return (
      <div className="test3">
        <Default>
          <form className="form" onSubmit={this.handleSubmit}>
            <label>
              <input
                type="text"
                value={this.state.value}
                onChange={this.handleChange}
                disabled={this.state.isFinal}
                className="inputText"/>
            </label>
            <input
              type="submit"
              value="Go"
              className="inputSubmit"
              disabled={this.state.isAnimating}/>
          </form>
        </Default>
        <Mobile>
          <form className="mobile_form" onSubmit={this.handleSubmit}>
            <input
              type="text"
              value={this.state.value}
              onChange={this.handleChange}
              disabled={this.state.isFinal}
              className="mobile_inputText"/>

            <input
              type="submit"
              value="Go"
              className="mobile_inputSubmit"
              disabled={this.state.isAnimating}/>
          </form>
        </Mobile>
      </div>
    );
  }
}

class StruggleBus extends Component {
  constructor(props) {
    super(props);

    const imgArray = [];
    imgArray[0] = "./Bus0LOOP.gif";
    imgArray[1] = "./Bus1.gif";
    imgArray[2] = "./Bus1LOOP.gif";
    imgArray[3] = "./Bus2.gif";
    imgArray[4] = "./Bus2LOOP.gif";
    imgArray[5] = "./Bus3.gif";
    imgArray[6] = "./Bus3LOOP.gif";
    imgArray[7] = "./Bus4.gif";
    imgArray[8] = "./Bus4LOOP.gif";
    imgArray[9] = "./Bus5.gif";
    imgArray[10] = "./BusFinalLOOP.gif";

    this.state = { imgArray: imgArray, currentIndex: 0 };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(struggle) {
    if (this.state.currentIndex < 10) {
      this.setState({ currentIndex: this.state.currentIndex + 1, isAnimating : true });
      this.props.addStruggle(struggle);
      if (this.state.currentIndex === 8) {
        this.setState({isFinal: true});
        setTimeout(() => {
          this.setState({ currentIndex: this.state.currentIndex + 1, isAnimating : false });
          this.props.clickBtn("Overload");
        }, 5000);
      } else {
      setTimeout(() => {
        this.setState({ currentIndex: this.state.currentIndex + 1, isAnimating : false });
      }, 3250);
    }
  }
  }

  render() {
    return (
      <Grid fluid className="StruggleBusMain">
        <Default>
          <Row middle="md">
            <Col xs={12} sm={5} md={4} lg={3}>
              <h2>Enter Your Struggle.</h2>
            </Col>
            <Col xs={12} sm={7} md={7} lg={6}>
              <StruggleForm
                submit_action={this.handleSubmit}
                isAnimating={this.state.isAnimating}/>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={5} md={4} lg={3}>
              <div className="results">
                {this.props.struggleArray.map((item, index) => (
                  <h3 key={index} item={item}>
                    {item}
                  </h3>
                ))}
              </div>
            </Col>
            <Col xs={12} sm={7} md={7}  lg={6} first="xs" last="sm">
              <BusImage
                currentIndex={this.state.currentIndex}
                array={this.state.imgArray}/>
            </Col>
          </Row>
        </Default>

        <Mobile className="mobile_background">
          <Row>
            <Col xs={12}>
              <h2 className="mobile_h2">Enter Your Struggle.</h2>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <StruggleForm
                submit_action={this.handleSubmit}
                isAnimating={this.state.isAnimating}
                className="mobile_background"/>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <BusImage
                className="mobile_BusImage"
                currentIndex={this.state.currentIndex}
                array={this.state.imgArray}/>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div className="mobile_results">
                {this.props.struggleArray.map((item, index) => (
                  <h3 className="mobile_h3" key={index} item={item}>
                    {item}
                  </h3>
                ))}
              </div>
            </Col>
          </Row>
        </Mobile>
      </Grid>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    ReactGA.initialize("UA-164355955-1");
    ReactGA.pageview(window.location.pathname +"App");

  }

  render() {
    return (
      <div className="App">
        <header> </header>
        <StruggleBus
          clickBtn={this.props.clickBtn}
          addStruggle={this.props.addStruggle}
          struggleArray={this.props.struggleArray}/>
      </div>
    );
  }
}

export default App;
