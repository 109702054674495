import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import LandingPage from "./LandingPage";
import Overload from "./Overload";
import Tshirt from "./Tshirt";
import * as serviceWorker from "./serviceWorker";
import Sound from "react-sound";
import ReactGA from "react-ga";
//import soundManager from "soundManager";

ReactGA.initialize("UA-164355955-1");
ReactGA.pageview(window.location.pathname + window.location.search);



class PageView extends Component {
 constructor(props) {
    super(props);
    
    var struggleArray = [];


    this.state = { renderView: "LandingPage", struggleArray: struggleArray, soundPosition: 100 };
  }


  addStruggle = (e) => {
  	this.state.struggleArray.push(e);
  }

  clickBtn = (e) => {
    if (e !== "Overload") {
      this.setState({
        renderView: e.target.getAttribute("name"),
      });

      if (e.target.getAttribute("name") === "LandingPage") {
    	this.setState({
        struggleArray: [],
      });
    }
    } else {
      this.setState({
        renderView: e,
      });
    }
  };


  render() {
    console.log("Switch State", this.state);
    // Giving up with this stupid canvas.
  	// <Canvas struggle_array={this.state.struggleArray} display="none" visibility="hidden" position="absolute"/>
    switch (this.state.renderView) {
      case "LandingPage":
        return <LandingPage clickBtn={this.clickBtn} />;
      case "App":
        return (
    	<div>
        	<Sound
		      url="images/struggle_Bus.mp3"
		      playStatus={Sound.status.PLAYING}
		      playFromPosition={100}
		      
		      loop="true"/>
        <App clickBtn={this.clickBtn} addStruggle={this.addStruggle} struggleArray={this.state.struggleArray}/>
        </div>);
      case "Overload":
      return(
      	<div>
         <Overload clickBtn={this.clickBtn} struggleArray={this.state.struggleArray}  />;
     	</div>
     	)
      case "Tshirt":
        return <Tshirt />;
      default:
        return <LandingPage clickBtn={this.clickBtn} />;
    }
  }


}

ReactDOM.render(
  <React.StrictMode>
    

    <PageView />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();










/*


  */
