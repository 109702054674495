import React, { Component } from 'react';
import './Tshirt.css';
import ReactGA from "react-ga";

class Tshirt extends Component {
  constructor(props) {
    super(props);
    ReactGA.initialize("UA-164355955-1");
    ReactGA.pageview(window.location.pathname +"Tshirt");

  }

  render() {
  return (
    <div>
      <h1> Why... Just why? </h1>  
    </div>
    );
}
}


export default Tshirt;
