import React, { Component } from "react";
import "./Overload.css";
import FadeIn from "react-fade-in";
import { Grid, Row, Col } from "react-flexbox-grid";
import { useMediaQuery } from "react-responsive";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
} from "react-share";

import ReactGA from "react-ga";

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

class Overload extends Component {

constructor(props) {
    super(props);
    ReactGA.initialize("UA-164355955-1");
    ReactGA.pageview(window.location.pathname +"Overload");

  }

  render() {
    const shareUrl = "strugglebus2020.com";
    const title = "strugglebus2020";
    const iconSize = 45;
    return (
      <div>
        <FadeIn delay="100" transitionDuration="1500">
          <h1> TOO MANY STRUGGLES</h1>
        </FadeIn>

        <Mobile>
          <img
            className="mobile_ashes"
            src="./images/cropped-BusFinalLOOP.gif"/>
        </Mobile>
        <Default>
          <Grid>
            <Row>
              <Col xs={12} sm={5} md={4} lg={3}></Col>
              <Col xs={12} sm={7} md={7} lg={6} first="xs" last="sm">
                <img
                  className="ashes"
                  src="./images/cropped-BusFinalLOOP.gif"/>
              </Col>
            </Row>
          </Grid>
        </Default>
        <FadeIn delay="600" transitionDuration="1500">
          <h1
            className="startOver"
            name="LandingPage"
            onClick={this.props.clickBtn}>
            {" "}
            Start over. >>{" "}
          </h1>
        </FadeIn>

        <footer>
          <FacebookShareButton url={shareUrl} hashtag={ "#" + title + " " + shareUrl}>
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>

          <TwitterShareButton
            url={shareUrl}
            title={"#"+title}
            hashtag={"#" + title}>
            <TwitterIcon size={iconSize} round />
          </TwitterShareButton>

          <EmailShareButton
            url={shareUrl}
            subject={"Come ride the struggle bus!"}
            body="This kinda helps?     "
            className="Demo__some-network__share-button">
            <EmailIcon size={iconSize} round />
          </EmailShareButton>

          <Default>
          <p className="tshirt" name="Tshirt" onClick={this.props.clickBtn}>
            Or click here to create a T-shirt of your struggles!
          </p>
          </Default>
          <Mobile>
          <p className="mobile_tshirt" name="Tshirt" onClick={this.props.clickBtn}>
            Or click here to create a T-shirt of your struggles!
          </p>

          </Mobile>
        </footer>
      </div>
    );
  }
}

export default Overload;
